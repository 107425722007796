/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

import { useRecoilValue } from 'recoil';
import currentFormAtom from '../../../../../../Atoms/currentForm';

import safelyParseJSON from '../../../../../../Utils/safelyParseJSON';
import { getOptionsAsync } from '../../../../../../Utils/engine/triggerApi';

const useStyles = makeStyles({
  skeletonBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 8,
  },
  skeletonCheckBox: {
    marginLeft: 8,
    marginRight: 16,
  },
  skeletonCheckLabel: {
    marginRight: 8,
  },
});

const RadioField = ({
  schema,
  id,
  uiSchema,
  disabled,
  formData,
  readonly,
  required,
  onChange,
  errorSchema,
  onBlur,
  onFocus,
}) => {
  const classes = useStyles();

  const currentForm = useRecoilValue(currentFormAtom);
  const [enumOptions, setEnumOptions] = useState([]);
  const [currentValue, setCurrentvalue] = useState(
    safelyParseJSON(formData) ?? ''
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      schema?.['@dynamicData'] &&
      schema['@dynamicData']?.['@paramsAtFetch'] &&
      schema['@dynamicData']?.['@fetched']
    ) {
      setEnumOptions(schema['@dynamicData']['@fetched']);
    }
    if (
      schema['@dynamicData']?.['@isFetching'] &&
      schema['@dynamicData']?.['@isFetching'] === true
    ) {
      setIsLoading(true);
    } else if (
      schema['@dynamicData']?.['@isFetching'] &&
      schema['@dynamicData']?.['@isFetching'] === false
    ) {
      setIsLoading(false);
    }
  }, [currentForm.dynamicData.updatedTimestamp]);

  useEffect(() => {
    setIsLoading(true);
    if (
      !schema?.['@dynamicData'] ||
      !schema['@dynamicData']?.['@paramsAtFetch'] ||
      !schema['@dynamicData']?.['@fetched']
    ) {
      getOptionsAsync(schema['@dynamicData']).then((filteredOptions) => {
        setEnumOptions(filteredOptions);
      });
    }
    setIsLoading(false);
  }, []);

  const _onChange = (_e, value) => {
    setCurrentvalue(`${value}`);
    onChange(`${value}`, errorSchema, 2);
  };

  const _onBlur = ({ target: { value } }) => onBlur(id, value);

  const _onFocus = ({ target: { value } }) => onFocus(id, value);

  const row = uiSchema ? uiSchema?.['ui:options']?.inline : false;

  return (
    <>
      <FormLabel htmlFor={id} required={required}>
        {schema.title}
      </FormLabel>
      {!isLoading ? (
        <>
          <RadioGroup
            row={row}
            value={`${currentValue}`}
            onBlur={_onBlur}
            onChange={_onChange}
            onFocus={_onFocus}
          >
            {enumOptions.map((option, i) => {
              const radio = (
                <FormControlLabel
                  control={<Radio color='primary' key={i} />}
                  disabled={disabled || readonly}
                  key={i}
                  label={`${option.label}`}
                  value={`${option.id}`}
                />
              );

              return radio;
            })}
          </RadioGroup>
          <FormHelperText>{schema.description}</FormHelperText>
        </>
      ) : (
        <FormGroup row={row}>
          <Box className={classes.skeletonBox}>
            <Skeleton
              className={classes.skeletonCheckBox}
              height={20}
              variant='rect'
              width={20}
            />
            <Skeleton
              className={classes.skeletonCheckLabel}
              height={20}
              variant='rect'
              width={250}
            />
          </Box>
          <Box className={classes.skeletonBox}>
            <Skeleton
              className={classes.skeletonCheckBox}
              height={20}
              variant='rect'
              width={20}
            />
            <Skeleton
              className={classes.skeletonCheckLabel}
              height={20}
              variant='rect'
              width={250}
            />
          </Box>
          <Box className={classes.skeletonBox}>
            <Skeleton
              className={classes.skeletonCheckBox}
              height={20}
              variant='rect'
              width={20}
            />
            <Skeleton
              className={classes.skeletonCheckLabel}
              height={20}
              variant='rect'
              width={250}
            />
          </Box>
        </FormGroup>
      )}
    </>
  );
};

export default RadioField;
